<template>
  <div class="batch-qrcode-container">
    <el-form :model="requestParam" label-width="80px">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="batchId" label="批次号">
            <el-input v-model="requestParam.batchId" placeholder="请输入批次号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="barCode" label="包含条码">
            <el-input v-model="requestParam.barCode" placeholder="请输入包含条码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="date" label="创建日期">
            <el-date-picker v-model="selectDatetime" @change="selectTime" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="createCode" type="primary">新增</el-button>
          <el-button type="primary" @click="downloadCode">下载条码</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table @select="selectTable" :data="batchCodeList.list" ref="batchCodeTable" border stripe class="batch-code">
      <!-- <el-table-column type="selection"></el-table-column> -->
      <el-table-column width="60">
        <template #default="scope">
          <el-radio v-model="choosedRowId" :label="scope.$index" @change="check=>{chooseRow(check,scope.row)}">
            &nbsp;
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="批次号" prop="batchId" />
      <el-table-column label="开始条码" prop="startBarCode" />
      <el-table-column label="截止条码" prop="endBarCode" />
      <el-table-column label="条码数量" prop="num" />
      <el-table-column label="创建日期" prop="created" />
      <el-table-column label="打印次数" prop="prints" />
      <el-table-column label="打印日期" prop="imei" />
      <el-table-column label="打印人" prop="createUserName" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button @click="checkCode(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next,jumper" :total="batchCodeList.total" @current-change="applyCurrentPage" />
    <el-dialog title="批量生产条码" v-model="dialogVisible">
      <el-form ref="createCodeForm" :model="createCodeForm" label-width="80px" :rules="formRules">
        <el-form-item prop="no" label="条码头">
          <el-input v-model="createCodeForm.no" placeholder="请输入条码头"></el-input>
        </el-form-item>
        <el-form-item prop="seq" label="开始序列">
          <el-input v-model="createCodeForm.seq" placeholder="请输入开始序列"></el-input>
        </el-form-item>
        <el-form-item prop="num" label="条码数量">
          <el-input type="number" v-model="createCodeForm.num" placeholder="请输入条码数量"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="batchCreateCode" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看条码明细" v-model="checkCodeDialogVisible">
      <el-table :data="checkCodeDetail" border stripe>
        <el-table-column label="条码" prop="barcode" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button @click="checkQRCode(scope.row)" type="text">查看二维码</el-button>
            <el-button @click="downloadQRCode(scope.row)" type="text">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkCodeDialogVisible = false">取 消</el-button>
        <el-button @click="batchCreateCode" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        requestParam: {
          batchId: "",
          barCode: "",
          startDate: "",
          endDate: "",
          page: 1,
          pageSize: 10,
        },
        batchCodeList: {
          list: [],
          page: 1,
          total_count: 0,
        },
        // 新增条码弹框是否可见
        dialogVisible: false,
        // 新增条码弹框数据对象
        createCodeForm: {
          no: "",
          seq: "",
          num: 0,
        },
        selectDatetime: [],
        formRules: {
          no: [{ required: true, message: "请输入条码头", trigger: "blur" }],
          seq: [{ required: true, message: "请输入开始序列", trigger: "blur" }],
          num: [
            { required: true, message: "请输入序列数量", trigger: "blur" },
            { min: 1, message: "请输入正确数量", trigger: "blur" },
          ],
        },
        // 选中行
        selections: [],
        // 查看条码弹框是否可见
        checkCodeDialogVisible: false,
        // 条码明细
        checkCodeDetail: [],
        choosedRowId: '',
        choosedRow: {}
      };
    },
    methods: {
      chooseRow(check, row) {
        this.choosedRow = row
        // console.log(check, row, '333')
      },
      // 查看二维码
      checkQRCode(row) {
        let url = "";
        if (process.env.NODE_ENV == "development") {
          url = "http://dev-server.haijingxiyi.com/wx/qrcode/" + row.barcode;
        } else {
          url = "https://server.haijingxiyi.com/wx/qrcode/" + row.barcode;
        }
        window.open(url);
      },
      downloadQRCode(row) {
        let url = "";
        if (process.env.NODE_ENV == "development") {
          url = "http://dev-server.haijingxiyi.com/wx/qrcode/" + row.barcode;
        } else {
          url = "https://server.haijingxiyi.com/wx/qrcode/" + row.barcode;
        }
        var alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.download = url;
        alink.click();
      },
      // 查看条码明细
      checkCode(row) {
        let params = {
          batchId: row.batchId,
        };
        this.$api.checkCodeDetail(params).then((data) => {
          console.log(data, "check code");
          this.checkCodeDetail = data;
          this.checkCodeDialogVisible = true;
        });
      },
      // 下载选中的条码，生成excel
      downloadCode() {
        let ids = [];
        // if (this.selections.length === 0) {
        //   this.$message.warning("请选择条码");
        //   return;
        // }
        // this.selections.map((item) => {
        //   ids.push(item.batchId);
        // });
        // let params = {
        //   batchId: ids.join(","),
        // };
        if (!this.choosedRow.batchId) {
          this.$message.warning("请选择条码");
          return
        }
        let params = {
          batchId: this.choosedRow.batchId
        }
        this.$api.downloadCode(params).then((data) => {
          let blob = new Blob([data], {
            // type: "application/vnd.ms-excel;charset=UTF-8",
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); // 转化为blob对象
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob, "222");
          } else {
            var blobURL = window.URL.createObjectURL(blob); // 将blob对象转为一个URL
            window.location.href = blobURL;
            // var tempLink = document.createElement("a"); // 创建一个a标签
            // tempLink.style.display = "none";
            // tempLink.href = blobURL;
            // tempLink.setAttribute("download", "111"); // 给a标签添加下载属性
            // if (typeof tempLink.download === "undefined") {
            //   tempLink.setAttribute("target", "_blank");
            // }
            // document.body.appendChild(tempLink); // 将a标签添加到body当中
            // tempLink.click(); // 启动下载
            // document.body.removeChild(tempLink); // 下载完毕删除a标签
            // window.URL.revokeObjectURL(blobURL);
          }
        });
      },
      selectTable(selection) {
        // this.$refs.batchCodeTable.clearSelection()
        console.log(selection, '111')
        this.selections = selection;
      },
      // 翻页
      applyCurrentPage(page) {
        this.requestParam.page = page;
        this.getBatchCodeList();
      },
      selectTime(data) {
        this.requestParam.startDate = data[0];
        this.requestParam.endDate = data[1];
      },
      search() {
        this.requestParam.page = 1;
        this.getBatchCodeList();
      },
      reset() { },
      // 获取批量条码记录
      getBatchCodeList() {
        this.$api.getBatchCodeList(this.requestParam).then((data) => {
          console.log(data, "get batch code list");
          this.batchCodeList = data;
          this.choosedRowId = data.list[0].batchId
        });
      },
      createCode() {
        this.dialogVisible = true;
        this.createCodeForm = {
          no: "",
          seq: "",
          num: 0,
        };
      },
      batchCreateCode() {
        this.$refs.createCodeForm.validate((valid) => {
          if (valid) {
            this.$api
              .batchCreateCode(this.createCodeForm)
              .then((data) => {
                this.dialogVisible = false;
                this.getBatchCodeList();
              })
              .catch((err) => { });
          }
        });
      },
    },
    created() {
      this.getBatchCodeList();
    },
    mounted() { },
  };
</script>
<style scoped lang="scss">
  .batch-qrcode-container {
    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }

    .dialog-footer {
      text-align: right;
    }

    .batch-code {
      margin-top: 20px;
    }
  }
</style>
